import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Section, PageTitle, MainTitle } from "../components/Core";
import { graphql, useStaticQuery } from "gatsby";
import Seo from "../components/Seo/Seo";
import { Link } from "gatsby";

const Sitemap = () => {

    const {
        pages: { nodes },
    } = useStaticQuery(graphql`
        {
          pages: allSitePage {
            nodes {
              path
            }
          }
        }
    `)

    const pages = nodes.map(node => node.path)

    const excludedLinks = [
        '/404.html',
        '/dev-404-page/',
        '/checkout-landing/LandingResidential/',
        '/careers/FreshStreamWidget/',
        '/checkout-landing/LandingBusiness/',
        '/appointment-success/',
        '/careers/test/',
        '/checkout-calendar/',
        '/checkout-landing/',
        '/checkout-landing/LandingBusiness/',
        '/checkout-landing/LandingResidential/',
        '/checkout/',
        '/light10/',
        '/events/appointment-error/',
        '/404/',
        '/launch/',
        '/payment/',
        '/register/',
        '/success-register-interest/',
        '/success-order/',
        '/events/request-sent/',
        '/janlaunch23-e/',
        '/janlaunch23-t/',
        '/janlaunch23-fb/',
        '/janlaunch23-ps/',
        '/sales/',
        '/installerdm/',
        '/psocialxmas22/',
        '/darlingtonfc/',
        '/bostonxmas/',
        '/wakefieldxmas/',
        '/gravesendxmas/',
        '/salesxmas22/',
        '/tci2/',
        '/e-xmas22/',
        '/psearchxmas22/',
        '/spennymoorfc/',
        '/t-xmas22/',
        '/kentcricket/',
        '/janlaunchdm/',
        '/tci/',
        '/rob/',
        '/ian/',
        '/adrian/',
        '/alex/',
        '/harry/',
        '/michael/',
        '/gary/',
        '/legal/price-book/',
        '/legal/december-500/',
        '/legal/december-2022/'
    ]


    const renderLinks = (pages) => {
        return pages.map((item) => (
            !excludedLinks.includes(item) && <div className="text-left">
                {
                    item !== '/' ? <Link to={item}>{renderLinkTitle(item)}</Link> : <Link to={'/'}>Home</Link>
                }
            </div>
        ))

    }

    const renderLinkTitle = (link) => {
        var newLink = link.slice(0, -1)
        var splitted = newLink.split('/')
        newLink = (splitted[splitted.length - 1]).replaceAll('-', ' ')
        newLink = newLink.charAt(0).toUpperCase() + newLink.slice(1);
        return newLink
    }

    return (
        <>
            <Seo page="sitemap" />
            <Section className="pb-0">
                <div className="pt-5" />
                <Container>
                    <Row className="justify-content-center text-center">
                        <Col lg="8">
                            <PageTitle>Sitemap</PageTitle>
                        </Col>
                        <Col lg="8">
                            {renderLinks(pages)}
                        </Col>
                    </Row>
                </Container>
            </Section>
            <br></br>
            <br></br>
        </>
    );
};
export default Sitemap;
